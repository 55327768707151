import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Button, Dropdown, message } from "antd"
import { getImageUrl } from "../../../../shared/utils/functions/images"
import DeleteIcon from '../../../../shared/assets/images/redTrash.svg'
import AssignUser from '../../../../shared/assets/images/User.svg';
import RenameDepart from '../../../../shared/assets/images/PencilAlt.svg'
import DeleteModal from "../../../../shared/components/Modals/DeleteModal"
import { errorMessage } from "../../../../shared/utils/constantData"
import { IDepartment, ISubDepartment } from "../../../../shared/utils/interfaces/departmentInterface";
import { selectDepartments, setDepartmentsData, setIsAddingNewDepartment, setSelectedDepartment, setSelectedSub } from "../../slice/departmentsSlice";
import { setIsInviteModalOpen } from "../../slice/departmentsSlice"
import { useDeleteDepartmentMutation, useDeleteSubDepartmentMutation } from "../../slice/departmentsApiSlice"
interface DepartmentProps {
    department: IDepartment,
    sub?: ISubDepartment
    isSub?: boolean;
    subDepartments?: ISubDepartment,
    editSub?: () => void,
}

const DepartmentActions: React.FC<DepartmentProps> = ({ department, isSub, editSub, sub }) => {
    const dispatch = useDispatch()
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
    const allDepartments = useSelector(selectDepartments);
    const [deleteDepartment, { isLoading, isSuccess, isError }] = useDeleteDepartmentMutation()
    const [deleteSubDepartment, { isLoading: isLoadingDeleteSub, isSuccess: isSuccessDeleteSub, isError: isErrorDeletingSub }] = useDeleteSubDepartmentMutation()

    useEffect(() => {
        if (isSuccessDeleteSub) {
            handleToggleDeleteModal()
            dispatch(setDepartmentsData(allDepartments.map((dept) => dept?.id === department?.id ? { ...dept, subDepartments: dept.subDepartments.filter(({ id }) => id !== sub?.id) } : dept)))
            message.success('Sub-department deleted successfully')
        } else if (isErrorDeletingSub) message.error(errorMessage)
        // eslint-disable-next-line
    }, [isSuccessDeleteSub, isErrorDeletingSub])

    useEffect(() => {
        if (isSuccess) {
            handleToggleDeleteModal()
            const updatedDepartments = allDepartments?.filter(dept => dept?.id !== department?.id);
            dispatch(setDepartmentsData(updatedDepartments));
            message.success('Department deleted successfully');
        } else if (isError) message.error(errorMessage)
        // eslint-disable-next-line
    }, [isSuccess, isError])

    const editDepartment = () => {
        const updatedDepartmentsList = allDepartments?.map((dep: IDepartment) => {
            if (dep.id === department.id) {
                return {
                    ...dep,
                    editParent: true,
                    subDepartments: dep?.subDepartments?.map((sub: ISubDepartment) => ({
                        ...sub,
                        editSub: false,
                    })),
                    updateSubButton: false,
                };
            } else {
                return {
                    ...dep,
                    editParent: false,
                    subDepartments: dep.subDepartments?.map((sub: ISubDepartment) => ({
                        ...sub,
                        editSub: false,
                    })),
                    updateSubButton: false,
                };
            }
        });
        dispatch(setDepartmentsData(updatedDepartmentsList));
        dispatch(setIsAddingNewDepartment(false));
    }
    const handleToggleDeleteModal = () => {
        setIsDeleteModalOpen(!isDeleteModalOpen)
        dispatch(setDepartmentsData(allDepartments?.map((dept) => dept?.id === department.id ? dept : { ...dept, editParent: false })));
    }
    const handleOpenInvite = () => {
        dispatch(setDepartmentsData(allDepartments?.map((dept) => dept?.id === department.id ? dept : { ...dept, editParent: false })));
        if (isSub) {
            dispatch(setSelectedSub(sub))
            dispatch(setSelectedDepartment(department))
        } else {
            dispatch(setSelectedSub({}))
            dispatch(setSelectedDepartment(department))
        }
        dispatch(setIsInviteModalOpen(true));
    }
    const handleDelete = async () => {
        isSub ? await deleteSubDepartment({ id: sub?.id }) : await deleteDepartment({ id: department?.id })
    }

    const moreCardActions = {
        items: [
            {
                key: 1,
                label: 'Rename',
                icon: <img src={RenameDepart} alt="rename" className="inline w-5 btn-icon !me-3" />,
                onClick: !isSub ? editDepartment : editSub,
                className: `!px-4 !text-indigo-700 hover:!bg-indigo-50`,
            },
            {
                key: 2,
                label: 'Assign Admin',
                icon: <img src={AssignUser} alt="assign member" className="inline w-5 btn-icon !me-3" />,
                onClick: handleOpenInvite,
                className: `!px-4 !text-indigo-700 hover:!bg-indigo-50`,
            },
            {
                key: 3,
                label: 'Delete',
                icon: <img src={DeleteIcon} alt="Delete" className="inline w-5 btn-icon !me-3" />,
                onClick: handleToggleDeleteModal,
                className: `px-4 !text-red-700 hover:!bg-indigo-50`,
            }
        ],
        className: '!p-2',
    }

    return (
        <>
            {
                <Dropdown className='inline my-auto cursor-pointer h-fit' trigger={['click']} menu={moreCardActions}>
                    <img src={getImageUrl('DotsHorizontalBlue')} alt='...' className="ms-2" />
                </Dropdown>
            }
            <DeleteModal
                isVisibleModal={isDeleteModalOpen}
                handleCloseModal={!isLoadingDeleteSub ? handleToggleDeleteModal : () => { }}
                title="Delete Department"
                content={<p>{isSub ? 'Are you sure that you want to delete this sub department?' : 'Are you sure that you want to delete this department'}<br />{isSub ? `Note: Jobs that belong to this sub department will not be deleted.` : 'and all sub departments?'}<br />{!isSub ? 'Note: Jobs that belong to this department will not be deleted.' : ''}</p>}
                footer={
                    <div className='grid gap-3 md:grid-cols-2'>
                        <Button
                            loading={isLoadingDeleteSub || isLoading}
                            className=' btn-md btn-danger'
                            onClick={handleDelete}>
                            Delete
                        </Button>
                        <Button
                            loading={isLoadingDeleteSub || isLoading}
                            disabled={isLoadingDeleteSub || isLoading}
                            className='btn-md'
                            htmlType='submit'
                            onClick={handleToggleDeleteModal}>
                            Cancel
                        </Button>
                    </div>}
            />

        </>
    )
}

export default DepartmentActions