
import { createSlice } from "@reduxjs/toolkit"
import { IDepartment, ISubDepartment } from "../../../shared/utils/interfaces/departmentInterface"

const departmentsSlice = createSlice({
    name: 'departments',
    initialState: {
        departmentsList: [],
        isAddingNewDepartment: false,
        isInviteModalOpen: false,
        selectedDepartment: {},
        selectedSub: {}
    },
    reducers: {
        setDepartmentsData: (state, action) => {
            state.departmentsList = action.payload
        },
        setIsAddingNewDepartment: (state, action) => {
            state.isAddingNewDepartment = action.payload
        },
        setIsInviteModalOpen: (state, action) => {
            state.isInviteModalOpen = action.payload
        },
        setSelectedDepartment: (state, action) => {
            state.selectedDepartment = action.payload
        },
        setSelectedSub: (state, action) => {
            state.selectedSub = action.payload
        },
    },
})

export const { setDepartmentsData, setIsAddingNewDepartment, setIsInviteModalOpen, setSelectedDepartment, setSelectedSub } = departmentsSlice.actions

export default departmentsSlice.reducer

export const selectDepartments = (state: { departments: { departmentsList: IDepartment[] } }) => state.departments.departmentsList
export const selectIsAddingNewDepartment = (state: { departments: { isAddingNewDepartment: boolean } }) => state.departments.isAddingNewDepartment
export const selectIsInviteModalOpen = (state: { departments: { isInviteModalOpen: boolean } }) => state.departments.isInviteModalOpen
export const selectSelectedDepartment = (state: { departments: { selectedDepartment: IDepartment } }) => state.departments.selectedDepartment
export const selectSelectedSub = (state: { departments: { selectedSub: ISubDepartment } }) => state.departments.selectedSub
