import SearchCndidateCard from '../modules/createJob/components/Candidates/candidatesSearch/SearchCndidateCard'
import Header from '../shared/components/Layout/Header'
import { useNavigate, useParams } from 'react-router-dom'
import { ISearchCandidate } from '../shared/utils/interfaces/candidatesListFace'
import { useGetSimilarApplicantsQuery } from '../modules/createJob/slice/candiddateApiSlice'
import LazyLoader from '../shared/components/LazyLoader/load'
import { Warning } from '../shared/components/Warning/Warning'
import { EmptyList } from '../shared/components/List/EmptyList'
import emptyIcon from '../shared/assets/images/empty.svg';
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectIsSendEmailOpen, selectSimilarCandidates, setCandidateProfile, setSimilarCandidates } from '../modules/createJob/slice/candidateSlice'
import { MoveCandidatesModal } from '../shared/components/Modals/MoveCandidatesModal'
import ComposeEmail from '../modules/createJob/components/Candidates/SendEmail/ComposeEmail'
import { apiStatuses, errorMessage } from '../shared/utils/constantData'

export default function SimilarityPage() {
    const navigate = useNavigate()
    const { applicationId } = useParams();
    const dispatch = useDispatch()
    const { data, isSuccess, isError, isFetching, isLoading, error } = useGetSimilarApplicantsQuery(applicationId)
    useEffect(() => {
        if (isSuccess) {
            dispatch(setSimilarCandidates(data?.data?.similarApplications))
            dispatch(setCandidateProfile(data?.data?.application))
        }
    }, [isSuccess, dispatch, data?.data])
    const similarCandidates = useSelector(selectSimilarCandidates)
    const isSendEmailOpen = useSelector(selectIsSendEmailOpen);

    return (
        <>
            <Header className='bg-white border-b border-neutral-200'>
                <span className='lg:w-[9rem] flex md:w-auto w-full mr-2 mb-2 text-xl font-semibold text-gray-600 capitalize min-w-max'>
                    Candidates Similarity
                </span>
            </Header>
            <div className='container my-7 wrapper'>
                    {
                    isLoading || isFetching ?
                            <div className="wrapper">
                                <div className="mt-20">
                                    <LazyLoader />
                                    <p className="mt-4 text-base font-normal text-indigo-600">Retrieving candidates list...</p>
                                </div>
                        </div>
                        : isSuccess && data?.data?.application && data?.data?.similarApplications?.length ?
                            <>
                                <div className='mb-8'>
                                    <div className="mb-3 text-xs font-semibold text-gray-900 uppercase">Reference candidate</div>
                                    <SearchCndidateCard
                                    onClick={() => navigate(`/jobs/${data?.data?.application?.jobId}/candidates/${data?.data?.application?.id}`)}
                                        query={''} candidate={data?.data?.application}
                                    />
                                </div>
                                <div>
                                    <div className="mb-3 text-xs font-semibold text-gray-900 uppercase">similar candidate(s)</div>
                                    {similarCandidates?.map((application: ISearchCandidate) => {
                                return (
                                    <div className='mb-3'>
                                        <SearchCndidateCard
                                            onClick={() => { navigate(`/jobs/${application?.jobId}/candidates/${application?.id}`) }}
                                            query={
                                                [
                                                    application?.similarityTypes?.includes("name") && application?.name,
                                                    application?.similarityTypes?.includes("email") && application?.email,
                                                    application?.similarityTypes?.includes("phone") && application?.phone,
                                                ].filter(Boolean).join(',')}
                                            candidate={application}
                                        />
                                    </div>)
                                        })}
                                </div>
                            </>
                            : isSuccess && !data?.data?.similarApplications?.length ?
                                <EmptyList
                                    title='No matching results'
                                    description='Try using different keywords to find who you are looking for.'
                                    image={emptyIcon}
                                    className='mt-24'
                                />
                                : isError ? (
                                    <Warning className="pt-24" title={error?.data?.statusCode === apiStatuses.requestForbidden ? "You don’t have access rights to view this candidate." : errorMessage} message={error?.data?.statusCode === apiStatuses.requestForbidden ? '' : 'Please refresh to try again'} btnText='Refresh' withRefreshBtn={false} />
                                )
                                    : null
                }
            </div>
            <MoveCandidatesModal />
            <div className={`${!isSendEmailOpen ? 'opacity-0 pointer-events-none' : ''} w-full pb-14`}>
                <ComposeEmail />
            </div>
        </>
    );
}
