import { useSelector } from 'react-redux';
import { selectAllRolesPermissions, selectCurrentUser } from '../../../modules/authentication/slice/authSlice';
import { permissionsActions } from '../interfaces/AuthInterface';

export const usePermissions = () => {
    const user = useSelector(selectCurrentUser);
    const allPermissions = useSelector(selectAllRolesPermissions);

    const hasAppPermission = (permission: permissionsActions) => {
        if (user?.distinctPermissions) {
            return user.distinctPermissions.includes(permission);
        }
    };

    const hasJobPermission = (jobRoleName: string, permission: permissionsActions) => {
        if (allPermissions) {
            const jobPermissions = allPermissions[jobRoleName]?.permissions
            return jobPermissions?.includes(permission);
        }
    };

    return { hasAppPermission, hasJobPermission };
};
