
import { useInviteNewUserMutation, useRemoveUserRoleMutation, useUpdateUserRoleMutation } from "../../slice/candiddateApiSlice";
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { errorMessage } from '../../../../shared/utils/constantData';
import { Button, Form, Input, Select, Space, Tag, message } from 'antd'
import ModalBody from '../../../../shared/components/Modals/ModalBody';
import { List } from 'antd';
import ListItem from '../../../../shared/components/List/ListItem';
import { IMemberItem } from '../../../../shared/utils/interfaces/createJobTabs';
import OptionContent from '../../../../shared/components/Dropdown/OptionContent';
import ProfileImage from '../../../../shared/components/NavBar/profileImage';
import { useGetMembersQuery, useGetMembersRolesQuery } from '../../slice/candiddateApiSlice';
import { IRole, RoleType } from "../../../../shared/utils/interfaces/AuthInterface";
import { EmptyList } from "../../../../shared/components/List/EmptyList";
import SkeletonList from '../../../../shared/components/List/SkeletonList';
import { IInvitationData, IUpdateUserRoleBody } from "../../../../shared/utils/interfaces/candidatesListFace";
import { useSelector, useDispatch } from "react-redux";
import { selectCompanyData } from "../../slice/companySlice";
import { selectJobId } from "../../slice/jobSlice";
import { truncate } from "../../../../shared/utils/functions/typography";
import { Warning } from "../../../../shared/components/Warning/Warning";
import { logEvent } from 'firebase/analytics';
import { analytics } from '../../../../shared/utils/analytics';
import ModalComponent from "../../../../shared/components/Modals/ModalComponent";
import { selectMembersCount, setMembersCount } from "../../slice/candidateSlice";
import WithDemoMode from "../../../../shared/components/WithDemoMode";
import { selectIsInviteModalOpen, selectSelectedDepartment, selectSelectedSub, setIsInviteModalOpen } from "../../../settings/slice/departmentsSlice";
import { useGetDepartmentMembersQuery } from "../../../settings/slice/departmentsApiSlice";

export default function JobMemebers({ inviteModalOpen, setInviteModalOpen }: { inviteModalOpen: boolean, setInviteModalOpen: Dispatch<SetStateAction<boolean>> }) {
    const dispatch = useDispatch()
    const { company, job, department } = RoleType
    const selectedDepartment = useSelector(selectSelectedDepartment)
    const selectedSub = useSelector(selectSelectedSub)
    const { data: deptData, isLoading: deptLoading, isSuccess: deptSuccess, isError: deptError, isFetching: deptFetching } = useGetDepartmentMembersQuery({ departmentId: selectedSub?.id ? selectedSub?.id : selectedDepartment?.id }, { skip: !selectedDepartment?.id });
    const [inviteNewUser, { data: inviteData, isLoading: isInvite, isSuccess: isInviteSuccess, isError: isInviteError, error: inviteError }] = useInviteNewUserMutation();
    const [updateUserRole, { isLoading: isUpdating, isSuccess: isUpdateSuccessfully, isError: hasUpdateError }] = useUpdateUserRoleMutation();
    const [removeUserRole, { isLoading: isRemoving, isSuccess: isRemoveSuccessfully, isError: hasRemoveError }] = useRemoveUserRoleMutation();
    const [form] = Form.useForm();
    const { Option } = Select;
    const [members, setMembers] = useState<IMemberItem[]>()
    const [selecteDMember, setSelectedMember] = useState<IMemberItem>()
    const jobId = useSelector(selectJobId)
    const companyIdSelector = useSelector(selectCompanyData)?.id
    const { data: memberData, isLoading: isLoadingMembers, isFetching, isSuccess, isError: hasMembersError } = useGetMembersQuery(jobId, { refetchOnMountOrArgChange: true, skip: !inviteModalOpen || selectedDepartment?.id })
    const { isLoading, isError, data } = useGetMembersRolesQuery(undefined, { skip: !inviteModalOpen || selectedDepartment?.id });
    const [selectedRole, setSelectedRole] = useState<IInvitationData>();
    const membersCount = useSelector(selectMembersCount);
    const isInviteModalOpen = useSelector(selectIsInviteModalOpen)
    useEffect(() => {
        if (isError) {
            message.error(errorMessage)
        }
        if (isInviteError) {
            message.error(inviteError?.data?.message)
        }
        if (isInviteSuccess) {
            dispatch(setMembersCount(membersCount + 1))
            form.resetFields();
            message.success(inviteData.message)
            logEvent(analytics, 'Add invitation')
        }
        // eslint-disable-next-line
    }, [isError, isInviteError, isInviteSuccess, form, inviteError, inviteData, dispatch]);

    useEffect(() => {
        if (isSuccess) {
            const pendingMembers = memberData?.pendingUsers.map((member: IMemberItem) => {
                return { ...member, isPending: true, isEditable: false }
            })
            setMembers([...memberData?.users, ...pendingMembers])
        }
    }, [isSuccess, memberData, isUpdateSuccessfully])

    useEffect(() => {
        if (deptSuccess) {
            const pendingDepartmentMembers = deptData?.data?.pendingUsers.map((member: IMemberItem) => {
                return { ...member, isPending: true, isEditable: false };
            });
            const allDepartmentMembers = [...deptData?.data?.users, ...pendingDepartmentMembers];
            setMembers(allDepartmentMembers);
            dispatch(setMembersCount(allDepartmentMembers?.length))
        }
    }, [deptSuccess, deptData, dispatch]);
    useEffect(() => {
        if (hasUpdateError) message.error(errorMessage)
        else if (isUpdateSuccessfully) message.success('Invitee role successfully changed')
        logEvent(analytics, 'member role has been changed')
    }, [isUpdateSuccessfully, hasUpdateError])

    useEffect(() => {
        if (hasRemoveError) message.error(errorMessage)
        else if (isRemoveSuccessfully) {
            setMembers((members) => members?.filter(({ id }) => selecteDMember?.id !== id))
            dispatch(setMembersCount(membersCount - 1))
            message.success('Invitee successfully removed')
        }
        // eslint-disable-next-line
    }, [isRemoveSuccessfully, hasRemoveError, selecteDMember, dispatch])

    const selectUserRole = (_value: string, option: any) => {
        setSelectedRole(option?.role)
    };
    const inviteNewMember = (value: IInvitationData) => {
        let inviteDataObj;
        if (selectedDepartment?.id) {
            inviteDataObj = {
                email: value?.email,
                roleId: 6,
                jobId: selectedSub?.id ? selectedSub?.id : selectedDepartment?.id,
                // in case job >> objectId will be jobId , in case company  >> objectId will be companyId
                objectId: selectedSub?.id ? selectedSub?.id : selectedDepartment?.id,
                objectType: department
            }
        } else {
            inviteDataObj = {
                email: value?.email,
                roleId: selectedRole?.id,
                jobId,
                // in case job >> objectId will be jobId , in case company  >> objectId will be companyId
                objectId: selectedRole?.isTopLevel ? companyIdSelector : jobId,
                objectType: selectedRole?.isTopLevel ? company : job
            }
        }
        inviteNewUser(inviteDataObj)

    }
    const handleChangeUserRole = async (value: string, selectedRole: { key: number, isTopLevel: boolean, memberId: number }) => {
        const { key, isTopLevel, memberId } = selectedRole
        setSelectedMember(members?.find(({ id }) => memberId === id))
        const updateRoleBody: IUpdateUserRoleBody = {
            userId: memberId,
            roleId: Number(key),
            objectId: isTopLevel ? companyIdSelector : jobId,
            objectType: isTopLevel ? company : job
        }
        const isRemove = !data?.roles?.some(({ name }: IRole) => value === name)
        if (isRemove) await removeUserRole(updateRoleBody)
        else {
            await updateUserRole(updateRoleBody)
            logEvent(analytics, 'member role has been changed')
        }
    }

    const removeDepartmentRole = async (memberId: number) => {
        const updateRoleBody: IUpdateUserRoleBody = {
            userId: memberId,
            roleId: 6,
            objectId: selectedSub?.id ? selectedSub?.id : selectedDepartment?.id,
            objectType: department
        }
        await removeUserRole(updateRoleBody)
    }

    const handleCloseInvite = () => {
        setInviteModalOpen(false);
        dispatch(setIsInviteModalOpen(false))
        form.resetFields()
    }

    const loadingState = useMemo(() => {
        return isFetching || isLoadingMembers || deptFetching || deptLoading
    }, [isFetching, isLoadingMembers, deptFetching, deptLoading]);

    const errorState = useMemo(() => {
        return hasMembersError || deptError;
    }, [hasMembersError, deptError]);

    const emptyListState = useMemo(() => {
        const noJobMembers = !isFetching && !memberData?.users?.length && !memberData?.pendingUsers?.length && !selectedDepartment?.id;
        const noDepartmentMembers = !deptFetching && !deptData?.data?.users?.length && !deptData?.data?.pendingUsers?.length && selectedDepartment?.id;
        return noJobMembers || noDepartmentMembers
    }, [isFetching, memberData, selectedDepartment, deptFetching, deptData]);

    const successState = useMemo(() => {
        return (isSuccess && !isFetching && !isLoadingMembers) || deptSuccess;
    }, [isSuccess, isFetching, isLoadingMembers, deptSuccess]);

    return (
        <ModalComponent
            className="md:!w-[36.25rem]"
            visibleModal={inviteModalOpen}
            handleCloseModal={handleCloseInvite}
            content={
                <ModalBody
                    className="text-start"
                    title={`Invite Members ${isInviteModalOpen && selectedSub?.id ? `to ${selectedSub?.name}` : selectedDepartment?.id ? `to ${selectedDepartment?.name}` : ''}`}
                >
                    <Form
                        form={form}
                        name="invite-member-form"
                        size='large'
                        className='form-inline'
                        onFinish={inviteNewMember}
                    >
                        <Form.Item className='flex-grow'>
                            <Space.Compact className='w-full' >
                                <Form.Item
                                    name='email'
                                    noStyle
                                    rules={[
                                        { required: true, message: 'Email is required' },
                                        { type: 'email' }
                                    ]}
                                    hasFeedback
                                >
                                    <Input placeholder="Add members by email"
                                        className={`${isInviteModalOpen ? '!rounded-lg' : ''}`}
                                    />
                                </Form.Item>
                                {!isInviteModalOpen ?
                                    <Form.Item
                                        name='role'
                                        noStyle
                                        rules={[{ required: true, message: 'Role is required' }]}
                                    >
                                        <Select onChange={selectUserRole} optionLabelProp='value' disabled={isError} loading={isLoading}
                                            className={`w-full ${!isInviteModalOpen ? '[&:not(:hover):not(:focus):not(:active)>.ant-select-selector]:!border-l-transparent' : ''} `}
                                            placeholder="Select role">
                                            {data?.roles?.map((role: IRole, key: number) => (
                                                <Option key={key} value={role?.name} role={role}>
                                                    <OptionContent title={role?.name ?? 'Role name'} description={role?.description ?? 'Role description'} />
                                                </Option>
                                            ))}
                                        </Select>
                                    </Form.Item> : null}
                            </Space.Compact>
                        </Form.Item >
                        <Form.Item>
                            <WithDemoMode toolTipPlacement="right" tooltipTitle="You are currently in Demo Mode, you can invite users only through your active account">
                                <Button htmlType='submit' loading={isInvite} className='btn-primary btn-lg'>Invite</Button>
                            </WithDemoMode>
                        </Form.Item>
                    </Form >
                    <ModalBody
                        title={`Members ${membersCount ? `(${membersCount})` : ''}`}
                        className="text-start"
                    >
                        {loadingState ?
                            <SkeletonList isLoading={true} hasLogoSkelton={true} />
                            : errorState ?
                                <Warning title={errorMessage} message='Please refresh to try again' btnText='Refresh' />
                                : emptyListState ?
                                    <EmptyList title="No members" /> :
                                    successState ?
                                        <List
                                            className='overflow-y-auto border-0 max-h-96'
                                            itemLayout="horizontal"
                                            dataSource={members}
                                            loading={isLoadingMembers || deptLoading}
                                            renderItem={({ id: memberId, firstName, lastName, email, role: { id, name, isTopLevel }, isEditable, isPending }: IMemberItem) => (
                                                <ListItem
                                                    key={memberId}
                                                    title={
                                                        <div className="flex flex-nowrap">
                                                            <span className="leading-5" title={`${firstName ?? email} ${lastName ?? ''}`}>
                                                                {truncate(`${firstName ?? email} ${lastName ?? ''}`, 18)}
                                                            </span>
                                                            {isPending && <Tag bordered={false} className="ant-tag-public ml-2.5 !text-xs rounded-md">pending approval</Tag>}
                                                        </div>
                                                    }
                                                    description={<span className="leading-5">{email}</span>}
                                                    className='text-gray-600'
                                                    avatar={<ProfileImage name={`${firstName ?? email} ${lastName ?? ''}`} />}
                                                    actions={[
                                                        !selectedDepartment?.id ? (
                                                            <Select
                                                                onSelect={handleChangeUserRole}
                                                                loading={isUpdating || isRemoving}
                                                                optionLabelProp='value'
                                                                className='!w-auto [&>.ant-select-selector]:!border-transparent bg-transparent'
                                                                value={name}
                                                                disabled={!isEditable}
                                                            >
                                                                {data?.roles?.map(({ id, name, description, isTopLevel }: IRole) => (
                                                                    <Option value={name} key={id} isTopLevel={isTopLevel} memberId={memberId}>
                                                                        <OptionContent title={name} description={description} />
                                                                    </Option>
                                                                ))}
                                                                <Option value="Remove Access" key={id} memberId={memberId} isTopLevel={isTopLevel}>
                                                                    <OptionContent danger title='Remove Access' />
                                                                </Option>
                                                            </Select>
                                                        ) : <Button className="border-none !bg-transparent shadow-none" disabled={!memberId} danger onClick={() => removeDepartmentRole(memberId)}>Remove Access</Button>]}
                                                />
                                            )}
                                        /> : null}
                    </ModalBody>
                </ModalBody>
            }
        />
    )
}
