import { Company } from "./companyInterface";
import { IPlan } from "./planInterface";
export interface User {
    id?: number
    email: string,
    firstName: string,
    lastName: string,
    password: string,
    photo?: string
    company: Company,
    plan: IPlan,
    companyId?: number,
    adminRole: Roles.admin | Roles.superAdmin | null,
    haveJobs: boolean,
    isHiringManager: boolean,
    connectedEmail: IConnectEmail | null,
    isDemoActive: boolean,
    isSampleTooltipDismissed: boolean,
    isCareerTooltipDismissed: boolean,
    distinctPermissions: permissionsActions[],
    roleIds: IRoleId[],
}
export interface IMentionMember {
    id: number | string,
    display: string,
    email: string
}
export interface loginFace {
    email: string;
    password: string;
    rememberMe: boolean;
}

export interface signupFace {
    firstName: string;
    lastName: string;
    jobTitle: string;
    email: string;
    password: string;
    newsletter: boolean;
    invitationId?: string;
}

export interface forgotPasswordFace {
    email: string;
}
export interface resetPasswordFace {
    newPassword: string;
}

export interface IConnectEmail {
    id: number,
    email: string,
    emailIntegrationId: EmailIntegrationTypes,
    lastUpdatedAt: string,
    createdAt: string,
    emailIntegrationTypes: {
        id: number,
        name: "Gmail" | "Outlook",
        createdAt: string
    }
}

export enum EmailIntegrationTypes {
    gmail = 1,
    outlook = 2
}

export interface passwordFieldFace {
    name: string,
    placeholder: string,
    isValid?: boolean,
    className?: string
}

export enum leftSideCaptions {
    signupTitle = 'Welcome to peopleCQ!',
    signupSubTitle = 'Create an account',
    otpTitle = 'One final step! Verify it’s you.',
    otpSubTitle = 'Please check your inbox for verification code',
    loginTitle = 'Sign in',
    loginSubTitle = 'Sign in to your account',
    forgetPasswordTitle = 'Forgot password!',
    forgetPasswordSubTitle = 'Reset your password',
    resetPasswordTitle = 'Reset password',
    resetPasswordSubTitle = 'Enter a new password'
}

export interface feedbackFormFace {
    feedbackType: string,
    comment: string
}
export interface IRole {
    id: number,
    name: Roles,
    description: string,
    isTopLevel: boolean
}
export enum RoleType {
    job = 'job',
    company = 'company',
    department = 'department',
}
export enum IRoleId {
    superAdmin = 1,
    admin,
    hiringManager,
    teamMember,
    sampleAdmin,
    deptAdmin,
}
export enum Roles {
    superAdmin = "SUPER_ADMIN",
    admin = 'ADMIN',
    hiringManager = 'HIRING_MANAGER',
    teamMember = 'TEAM_MEMBER',
}
export enum permissionsActions {
    //used
    generateJob = 'generateJob',
    updateEssentials = 'updateEssentials',
    updateSection = 'updateSection',
    publishJob = 'publishJob',
    inviteMembers = 'getRoles',
    updateJobHiringStage = 'updateJobHiringStatge',
    getInterviewQuestions = 'getInterviewQuestions',
    uploadCVs = 'applicationBulkUpload',
    sendEmail = 'sendEmail',
    reports = 'getReport',
    changeApplicationJob = 'changeApplicationJob',
    deleteCandidates = 'deleteCandidate',
    searchCandidates = 'searchApplications',
    getAutomationTemplates = 'getAutomationTemplates',
    switchToDemo = "switchToDemo",
    deleteJob = "deleteJob",
    closeJob = "closeJob",
    updatePrimaryPipeline = "updatePrimaryPipeline",
    getPipelines = "getPipelines",
    getDepartment = "getDepartment",
    getTemplates = "getTemplates",
    getDomainConfig = "getDomainConfig",
    disconnectEmail = "disconnectEmail",
    //un used
    regenerateJob = 'regenerateJob',
    updateJob = 'updateJob',
    generateSection = 'generateSection',
    getAllJobs = 'getAllJobs',
    getJobByID = 'getJobByID',
    updateUserPlan = 'updateUserPlan',
    getPaddleCustomer = 'getPaddleCustomer',
    getJobApplications = 'getJobApplications',
    updateApplication = 'updateApplication',
    updateCompany = 'updateCompany',
}