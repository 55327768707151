
import { createSlice } from "@reduxjs/toolkit"
import { ICreateJobBody, IJobData } from "../../../shared/utils/interfaces/generateJobFace"
import { tabs } from "../../../shared/utils/interfaces/createJobTabs"
const jobSlice = createSlice({
    name: 'job',
    initialState: {
        batchId: null,
        id: 0, date: '',
        statusId: 0,
        essentialInfo: {},
        jobTitle: '',
        isEditingEssentials: false,
        isEssentialModalOpen: false,
        activeTab: tabs.description as string,
        isApplyModalOpen: false,
        isAnimationReady: false,
        isUploadModalOpen: false,
        uploadCVsJobId: null,
        settingsTooltipOpen: false,
        isLoadingSampleData: false,
        isCopyLink: false
    },
    reducers: {
        setJobInfo: (state, action) => {
            const { id, date, statusId } = action.payload
            state.id = id ?? state.id
            state.date = date ?? state.date
            state.statusId = statusId ?? state.statusId
        },
        setEssentialJobInfo: (state, action: { payload: ICreateJobBody | {} }) => {
            state.essentialInfo = action.payload
        },
        setJobTitle: (state, action: { payload: string }) => {
            state.jobTitle = action.payload
        },
        setIsEditingEssentials: (state, action: { payload: boolean }) => {
            state.isEditingEssentials = action.payload;
        },
        setIsEssentialsModalOpen: (state, action: { payload: boolean }) => {
            state.isEssentialModalOpen = action.payload;
        },
        setActiveTab: (state, action: { payload: string }) => {
            state.activeTab = action.payload;
        },
        setIsApplyModalOpen: (state, action: { payload: boolean }) => {
            state.isApplyModalOpen = action.payload;
        },
        setIsAnimationReady: (state, action: { payload: boolean }) => {
            state.isAnimationReady = action.payload
        },
        setIsUploadModalOpen: (state, action: { payload: boolean }) => {
            state.isUploadModalOpen = action.payload
        },
        setUploadCVsJobId: (state, action) => {
            state.uploadCVsJobId = action.payload
        },
        setBatchId: (state, action) => {
            state.batchId = action.payload
        },
        setSettingsTooltipOpen: (state, action) => {
            state.settingsTooltipOpen = action.payload
        },
        setIsLoadingSampleData: (state, action) => {
            state.isLoadingSampleData = action.payload;
        },
        setIsCopyLink: (state, action) => {
            state.isCopyLink = action.payload;
        },
    },
})

export const { setBatchId, setJobInfo, setEssentialJobInfo, setJobTitle, setIsEditingEssentials, setIsEssentialsModalOpen, setActiveTab, setIsApplyModalOpen, setIsAnimationReady, setIsUploadModalOpen, setUploadCVsJobId, setSettingsTooltipOpen, setIsLoadingSampleData, setIsCopyLink } = jobSlice.actions

export default jobSlice.reducer

export const selectJobId = (state: { job: IJobData }) => state.job.id
export const selectJobDate = (state: { job: IJobData }) => state.job.date
export const selectJobStatusId = (state: { job: IJobData }) => state.job.statusId
export const selectEssentialJobInfo = (state: { job: IJobData }) => state.job.essentialInfo
export const selectJobTitle = (state: { job: { jobTitle: string } }) => state.job.jobTitle
export const selectIsEditingEssentials = (state: { job: { isEditingEssentials: boolean } }) => state.job.isEditingEssentials
export const selectIsEssentialModalOpen = (state: { job: { isEssentialModalOpen: boolean } }) => state.job.isEssentialModalOpen
export const selectActiveTab = (state: { job: { activeTab: string } }) => state.job.activeTab
export const selectIsApplyModalOpen = (state: { job: { isApplyModalOpen: boolean } }) => state.job.isApplyModalOpen
export const selectIsAnimationReady = (state: { job: { isAnimationReady: boolean } }) => state.job.isAnimationReady
export const selectIsUploadModalOpen = (state: { job: { isUploadModalOpen: boolean } }) => state.job.isUploadModalOpen
export const selectUploadCVsJobId = (state: { job: { uploadCVsJobId: number } }) => state.job.uploadCVsJobId
export const selectBatchId = (state: { job: { batchId: number } }) => state.job.batchId
export const selectSettingsTooltipOpen = (state: { job: { settingsTooltipOpen: boolean } }) => state.job.settingsTooltipOpen
export const selectIsLoadingSampleData = (state: { job: { isLoadingSampleData: boolean } }) => state.job.isLoadingSampleData;
export const selectIsCopyLink = (state: { job: { isCopyLink: boolean } }) => state.job.isCopyLink;
