export const highlightText = (text: string | number, query: string) => {
    if (!text || !query) return text;

    // Ensure both text and query are strings for consistency
    const textStr = String(text);

    // Split the query into parts, escape each part, and create a regex pattern to match any of them
    const parts = query.split(',').map(part => part.trim()).filter(Boolean);
    const escapedParts = parts.map(part => part.replace(/([.*+?^${}()|[\]\\])/g, '\\$&'));
    const regex = new RegExp(`(${escapedParts.join('|')})`, 'gi');

    // Split the text using the regex pattern and wrap matching portions in <span> for highlighting
    const matches = textStr.split(regex).map((part, index) => {
        if (index % 2 === 1) { // If the index is odd, it's a match
            return <span key={index} className="font-bold text-indigo-500">{part}</span>;
        }
        return part;
    });

    return matches;
};
