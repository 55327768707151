import { EmptyList } from '../../../../shared/components/List/EmptyList'
import { Button } from 'antd'
import EmptyDepartments from '../../../../shared/assets/images/NoDepartments.svg'
import { useDispatch } from 'react-redux'
import { setIsAddingNewDepartment } from '../../slice/departmentsSlice'

export const NoDepartments = () => {
    const dispatch = useDispatch()
    return (
        <>
            <div className='flex items-center justify-center'>
                <EmptyList
                    title='Your account has no departments'
                    description='Why not consider adding some to organize your jobs?'
                    image={EmptyDepartments}
                    className='mt-24'
                />
            </div>
            <Button onClick={() => dispatch(setIsAddingNewDepartment(true))} type="primary" className='flex justify-center items-center mx-auto px-4 mt-2 h-[42px] text-base font-medium leading-normal text-white bg-indigo-600 rounded-md shadow '>
                Add The First Department
            </Button>
        </>
    )
}
