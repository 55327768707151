export enum reportsLayout {
    main,
    category
}

export enum reportsCategories {
    pipeline = 'hiring-pipeline',
    candidates = 'candidates',
    hiringVelocity = 'hiring-velocity',
    activity = 'activity',
    timeToFill = 'time-to-fill',
    openJobs = 'open-jobs'
}

export interface stages {
    hiring_stage_title: string;
    applications_count: string;
    hiring_stage_color: string;
    hiring_stage_id: number;
    company_stage_type_id: number;
    global_statge_id: number;
    global_statge_color: string;
    hiring_stage_title_suffix?: string;
}
export interface pipelineJob {
    jobID: number;
    stages: stages[];
    title: string;
    candidatesCount: number;
}

export interface IRePortsFilter {
    page: number,
    perPage: number,
    jobIds?: number,
    fromDate?: ''
    toDate?: ''
}

export const categoriesDispaly: { [key: string]: string } = {
    [reportsCategories.pipeline]: 'Hiring Pipeline',
    [reportsCategories.candidates]: 'Candidates',
    [reportsCategories.hiringVelocity]: 'Hiring Velocity',
    [reportsCategories.activity]: 'Activity',
    [reportsCategories.timeToFill]: 'Time to Fill',
    [reportsCategories.openJobs]: 'Open Jobs'
}