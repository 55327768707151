import { Tooltip, TooltipProps } from "antd"

export const WithTooltip = ({ visible = true, children, title, placement, width, className, color, backgroundColor, colorText }: { visible?: boolean, children: React.ReactNode, title: string | React.ReactNode, placement: 'top' | 'bottom' | 'right' | 'left', width?: string, className?: string, color?: string, backgroundColor?: string, colorText?: string }) => {
    const tooltipProps: TooltipProps = {
        title,
        placement,
        color: backgroundColor ? backgroundColor : "#A5A5BA",

    }
    return visible ?
        <Tooltip {...tooltipProps} overlayInnerStyle={{ paddingTop: '0.5rem', paddingBottom: '0.5rem', backgroundColor: backgroundColor ?? 'inherit', color: colorText ?? '' }} overlayClassName={`text-xs font-normal ${width ? width : 'w-28'} ${className ?? ''}`}>
            {children}
        </Tooltip>
        :
        <>
            {children}
        </>

}