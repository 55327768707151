import React from "react";
import { useDemoMode } from "../utils/customHooks/useDemoHook";
import { WithTooltip } from "./tooltip.tsx/withTootip";

function WithDemoMode({ children, tooltipTitle, toolTipClassName, toolTipPlacement = 'bottom' }: { children: any, tooltipTitle: string, toolTipClassName?: string, toolTipPlacement?: 'top' | 'bottom' | 'left' | 'right' }) {
    const { isDemoMode } = useDemoMode();

    return (
        <>
            <WithTooltip
                visible={!!isDemoMode}
                placement={toolTipPlacement}
                className={`w-[9.688rem] ${toolTipClassName ?? ''}`}
                title={tooltipTitle}
            >
                <div className="flex items-center h-full">
                    {React.cloneElement(children, { disabled: isDemoMode })}
                </div>
            </WithTooltip>
        </>
    )
}

export default WithDemoMode