import { apiSlice } from "../../../shared/slices/apiSlice";
import { tagTypes } from "../../../shared/utils/constantData";
const { members, jobs, departments } = tagTypes
export const departmentsApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder: any) => ({
        createNewDepartment: builder.mutation({
            query: (initialDepartmentData: { name: string }) => {
                return {
                    url: `/companies/departments`,
                    method: 'POST',
                    body: initialDepartmentData
                }
            },
        }),
        createNewSubDepartment: builder.mutation({
            query: (initialDepartmentData: { name: string, parentDepartmentId: number }) => {
                return {
                    url: `/companies/subDepartments`,
                    method: 'POST',
                    body: initialDepartmentData
                }
            }
        }),
        updateDepartment: builder.mutation({
            query: (initialDepartmentData: { name: string, id: number }) => {
                return {
                    url: `/companies/departments`,
                    method: 'PATCH',
                    body: initialDepartmentData
                }
            },
            
        }),
        updateSupDepartment: builder.mutation({
            query: (initialSubDepartmentData: { name: string, id: number, parentDepartmentId: number }) => {
                return {
                    url: `/companies/subDepartments`,
                    method: 'PATCH',
                    body: initialSubDepartmentData
                }
            },
            
        }),
        getCompanyDepartments: builder.query({
            query: () => {
                return {
                    url: '/companies/departments',
                }
            }, providesTags: [departments],
        }),
        deleteDepartment: builder.mutation({
            query: ({ id }: { id: number }) => ({
                url: `/companies/departments/${id}`,
                method: "DELETE"
            }),
            invalidatesTags: [jobs, departments]
        }),
        deleteSubDepartment: builder.mutation({
            query: ({ id }: { id: number }) => ({
                url: `/companies/subdepartments/${id}`,
                method: "DELETE"
            }),
            invalidatesTags: [jobs],
        }),
        getDepartmentMembers: builder.query({
            query: ({ departmentId }: { departmentId: number }) => {
                return {
                    url: `/companies/departments/${departmentId}/members/`,
                }
            },
            providesTags: [members]
        }),
    })
})
export const { useCreateNewDepartmentMutation, useGetCompanyDepartmentsQuery,
    useCreateNewSubDepartmentMutation, useUpdateDepartmentMutation, useUpdateSupDepartmentMutation, useDeleteDepartmentMutation, useDeleteSubDepartmentMutation, useGetDepartmentMembersQuery } = departmentsApiSlice
