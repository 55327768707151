import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Input, message } from 'antd';
import addPlus from '../../../../shared/assets/images/Plus circle.svg';
import subDepartmentVector from '../../../../shared/assets/images/subDepartmentsVector.svg';
import subDepartmentDashed from '../../../../shared/assets/images/subeDepartmentVectorDashed.svg';
import { IDepartment, ISubDepartment } from '../../../../shared/utils/interfaces/departmentInterface';
import { selectDepartments, setDepartmentsData, setIsAddingNewDepartment } from '../../slice/departmentsSlice';
import { useCreateNewSubDepartmentMutation, useUpdateSupDepartmentMutation } from '../../slice/departmentsApiSlice';
import DepartmentActions from './Actions';

export const ViewDepartment: React.FC<{ department: IDepartment }> = ({ department }) => {
    const [createNewSubDepartment, { isLoading, isSuccess, data, isError }] = useCreateNewSubDepartmentMutation();
    const [updateCurrentSubDepartment, { isLoading: isLoadingUpdate, isSuccess: isSuccessUpdate, data: dataUpdate, isError: isErrorUpdate }] = useUpdateSupDepartmentMutation();

    const dispatch = useDispatch();
    const allDepartments = useSelector(selectDepartments);
    const [subName, setSubName] = useState<string>('');
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [editingSubDepartment, setEditingSubDepartment] = useState<ISubDepartment | null>(null);
    const [isAddingNew, setIsAddingNew] = useState<boolean>(false);
    const newInputRef = useRef<any>(null);
    const lastSubDepartmentRef = useRef<any>(null);


    const addNewSub = () => {
        dispatch(setDepartmentsData(allDepartments?.map((dept) => {
            if (dept?.id === department?.id) {
                return {
                    ...dept,
                    subDepartments: dept?.subDepartments?.map((sub) => ({
                        ...sub,
                        editSub: false
                    })),
                    updateSubButton: true,
                    editParent: false,
                };
            } else {
                return {
                    ...dept,
                    editable: false,
                    subDepartments: dept?.subDepartments?.map((sub) => ({
                        ...sub,
                        editSub: false
                    })),
                    updateSubButton: false,
                    editParent: false,

                };
            }
        })));
    
        setIsAddingNew(true);
        setIsEditing(true);
        setSubName('');
        setEditingSubDepartment(null);
        dispatch(setIsAddingNewDepartment(false));

    };

    
    
    const cancelNewSub = () => {
        dispatch(setDepartmentsData(
            allDepartments?.map((dept) => {
                if (dept?.id === department?.id) {
                    return {
                        ...dept,
                        subDepartments: dept?.subDepartments?.map((sub) => ({
                            ...sub,
                            editSub: false
                        })),
                        editable: false,
                        updateSubButton:false
                    };
                }
                return dept;
            })
        ));

        setIsAddingNew(false);
        setIsEditing(false);
        setSubName('');
        setEditingSubDepartment(null);
    };


    

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSubName(e?.target?.value);
    };

    const saveSubDepartment = async () => {
        if (subName?.trim()?.length > 0) {
            const body = {
                parentDepartmentId: department?.id,
                name: subName
            };
            await createNewSubDepartment(body);
            setIsEditing(false);
            setIsAddingNew(false);
            setEditingSubDepartment(null);
            setSubName('');
        } else {
            message.error('Add sub-department');
        }
    };

    const updateSubDepartment = async () => {
        if (!editingSubDepartment) return;

        const updatedSubDepartmentV: ISubDepartment = {
            id: editingSubDepartment?.id,
            name: subName,
            parentDepartmentId: department?.id,
        };
        await updateCurrentSubDepartment(updatedSubDepartmentV);
        setIsEditing(false);
        setEditingSubDepartment(null);
        setSubName('');
    };

    const updateDepartmentsList = (updatedDepartment: IDepartment) => {
        const updatedDepartmentsList = allDepartments?.map((dep: IDepartment) =>
            dep?.id === updatedDepartment?.id ? updatedDepartment : dep
        );
        dispatch(setDepartmentsData(updatedDepartmentsList));
    };
    useEffect(() => {
        if (isSuccess) {
            const resData = {
                ...data?.data,
                editable: false,
                subDepartments: data?.data?.subDepartments?.map((sub:any) => ({
                    ...sub,
                    editSub: false
                })),
            };
            
            updateDepartmentsList(resData);
            message.success(data?.message);
            setIsEditing(false);
            setIsAddingNew(false);
        }

        if (isError) {
            const resData = {
                ...data?.data,
                editable: false,
                subDepartments: data?.data?.subDepartments?.map((sub:ISubDepartment) => ({
                    ...sub,
                    editSub: true
                })),
            };
            
            updateDepartmentsList(resData);
            message.error(`Something went wrong with ${department?.name} department`);
            setIsEditing(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSuccess, isError, data, department?.name]);

   
    const handleEditSubDepartments = (subDepartment: ISubDepartment) => {
        const updatedDepartments = allDepartments?.map((dept) => {
            if (dept?.id === department?.id) {
                return {
                    ...dept,
                    subDepartments: dept?.subDepartments?.map((sub) => ({
                        ...sub,
                        editSub: sub?.id === subDepartment?.id,
                    })),
                    updateSubButton: true,
                    editParent:false,
                };
            }
            return {
                ...dept,
                subDepartments: dept?.subDepartments?.map((sub) => ({
                    ...sub,
                    editSub: false,
                })),
                updateSubButton: false,
                editParent:false,

            };
        });

        dispatch(setDepartmentsData(updatedDepartments));
        setEditingSubDepartment(subDepartment);
        setSubName(subDepartment.name);
        setIsEditing(true);
        dispatch(setIsAddingNewDepartment(false));

    };
    
    

    useEffect(() => {

        if (isSuccessUpdate) {
            if (!editingSubDepartment) return;
            const updatedDepartment = {
                ...dataUpdate?.data,
                editable: false,
                subDepartments: dataUpdate?.data?.subDepartments?.map((sub:any) => ({
                    ...sub,
                    editSub: false
                })),
                
            };
            updateDepartmentsList(updatedDepartment);
            setIsEditing(false);
            setEditingSubDepartment(null);
            setSubName('')
            dispatch(setIsAddingNewDepartment(false));
            message.success('Sub-department updated successfully');


        }

        if (isErrorUpdate) {
            message.error(`Something went wrong with sub department`);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSuccessUpdate, isErrorUpdate, dataUpdate]);

    useEffect(() => {
        if (isAddingNew || (isEditing && editingSubDepartment)) {
            newInputRef?.current?.focus();
        }
    }, [isAddingNew, isEditing, editingSubDepartment]);
    
    return (
        <div className="w-[16.625rem] h-[12rem] p-5 bg-white rounded-2xl flex flex-col justify-between items-start">
            <div className="self-stretch flex-col justify-start items-start gap-1.5 flex">
                <div className="inline-flex items-center self-stretch justify-between">
                    <div className="text-gray-600 text-base font-bold font-['Inter'] leading-normal">{department?.name}</div>
                    <DepartmentActions department={department} />
                </div>

                <div className="flex flex-col gap-2 max-h-[5rem] overflow-y-scroll w-full scrollbar-hide">
                    {department?.subDepartments?.map((sub: ISubDepartment) => (
                        !sub?.editSub && (
                            <div key={sub?.id} className="flex items-center justify-between ps-1">
                                <div className="flex items-center">
                                    <span className="flex">
                                        <img src={sub?.editSub ? subDepartmentDashed : subDepartmentVector} className="pe-2" alt="subDepartmentVector" />
                                    </span>
                                    {sub?.editSub ? (
                                        <Input
                                            placeholder="Write sub depart. name"
                                            className="text-gray-600 text-base leading-normal py-2.5 h-6"
                                            size="small"
                                            value={subName}
                                            bordered={false}
                                            onChange={handleInputChange}
                                            ref={editingSubDepartment?.id === sub?.id ? lastSubDepartmentRef : null}
                                            onPressEnter={editingSubDepartment ? updateSubDepartment : saveSubDepartment}
                                        />
                                    ) : (
                                        <span className="pt-3 text-base font-normal leading-normal text-gray-600">{sub?.name}</span>
                                    )}
                                </div>
                                {!department?.updateSubButton && (
                                    <DepartmentActions
                                        department={department}
                                        sub={sub}
                                        isSub
                                        editSub={() => handleEditSubDepartments(sub)}
                                    />
                                )}
                            </div>
                        )
                    ))}
                    {department.updateSubButton && (
                        <div className="flex items-center justify-between ps-1">
                            <div className="flex items-center">
                                <span className="flex">
                                    <img src={subDepartmentDashed} className="pe-2" alt="subDepartmentVector" />
                                </span>
                                <Input
                                    placeholder="Write sub depart. name"
                                    className="text-gray-600 text-base leading-normal py-2.5 h-6"
                                    size="small"
                                    value={subName}
                                    bordered={false}
                                    onChange={handleInputChange}
                                    onPressEnter={editingSubDepartment ? updateSubDepartment : saveSubDepartment}
                                    ref={newInputRef}
                                />
                            </div>
                        </div>
                    )}
                </div>
            </div>

            <div className="flex justify-center w-full mt-4">
                {department?.updateSubButton ? ( 
                    <div className='flex w-full'>
                        <Button
                            onClick={editingSubDepartment ? updateSubDepartment : saveSubDepartment}
                            type="primary"
                            loading={isLoading || isLoadingUpdate}
                            disabled={isLoading || isLoadingUpdate || subName?.trim()?.length === 0}
                            className={`me-2 w-full text-sm font-medium leading-none ${subName?.trim().length > 0 && !isLoading ? 'bg-indigo-600' : 'bg-indigo-200 cursor-not-allowed hover:!bg-indigo-200'
                                }`}
                        >
                            {editingSubDepartment  ? 'Update' : 'Save'}
                        </Button>
                        <Button onClick={cancelNewSub}>X</Button>

                    </div>

                ) : (
                    <Button onClick={addNewSub} className="flex items-center border border-gray-300 px-7">
                        <span className="flex items-center mr-2">
                            <img src={addPlus} alt="addPlus" />
                        </span>
                        Add Sub Department
                    </Button>
                )}
            </div>
        </div>
    );
};

export default ViewDepartment;