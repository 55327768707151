import { useDispatch, useSelector } from "react-redux";
import { AddNewDepartment } from "../modules/settings/components/Departments/AddNewDepartment";
import NewDepartments from "../modules/settings/components/Departments/NewDepartments";
import { NoDepartments } from "../modules/settings/components/Departments/NoDepartments";
import { ViewDepartment } from "../modules/settings/components/Departments/ViewDepartment";
import { selectDepartments, selectIsAddingNewDepartment, selectIsInviteModalOpen, setDepartmentsData, setIsAddingNewDepartment, setIsInviteModalOpen, setSelectedDepartment, setSelectedSub } from "../modules/settings/slice/departmentsSlice";
import { useGetCompanyDepartmentsQuery } from "../modules/settings/slice/departmentsApiSlice";
import { useEffect } from "react";
import LazyLoader from "../shared/components/LazyLoader/load";
import { Warning } from "../shared/components/Warning/Warning";
import { IDepartment, ISubDepartment } from "../shared/utils/interfaces/departmentInterface";
import JobMemebers from "../modules/createJob/components/Candidates/JobMemebers";

export const Departments = () => {
    const dispatch = useDispatch()
    const departments = useSelector(selectDepartments)
    const isAddingNewDepartment = useSelector(selectIsAddingNewDepartment)
    const { isLoading, isSuccess, isError, data, isFetching } = useGetCompanyDepartmentsQuery(undefined, { refetchOnMountOrArgChange: true })
    const isInviteModalOpen = useSelector(selectIsInviteModalOpen)

    useEffect(() => {
        if (isSuccess) {
            const addEditableKey = data?.data?.map((item: IDepartment) => {
                const updatedSubDepartments = item?.subDepartments?.map((subItem: ISubDepartment) => {
                    return {
                        ...subItem,
                        editSub: false,
                    }
                }) || [];
                return {
                    ...item,
                    editable: false,
                    editParent: false,
                    subDepartments: updatedSubDepartments,
                    updateSubButton:false
                }
            })
            dispatch(setDepartmentsData(addEditableKey))
        }
    }, [isSuccess, data, dispatch])

    useEffect(() => {
        return () => {
            dispatch(setIsAddingNewDepartment(false))
            dispatch(setSelectedDepartment({}))
            dispatch(setSelectedSub({}))
        }
    }, [dispatch])

    return (
        <>
            <JobMemebers setInviteModalOpen={setIsInviteModalOpen} inviteModalOpen={isInviteModalOpen} />
            {isLoading || isFetching ? <div className="wrapper">
                <div className="mt-20">
                    <LazyLoader />
                    <p className="mt-4 text-base font-normal text-indigo-600">Retrieving departments list...</p>
                </div>
            </div> : isSuccess ?
                !departments?.length && !isAddingNewDepartment ? < NoDepartments />
                    : isAddingNewDepartment && !departments?.length ? <div className="flex items-center justify-center my-52"><NewDepartments id={0} name={""} subDepartments={[]} editable={false} editParent={false} /></div>
                        : <div className="mx-4 justify-self-center md:mx-16 lg:mx-28 xl:mx-44">
                            <div className="flex items-center justify-center my-20">
                                <div className="w-full px-2 mx-auto sm:px-4">
                                    <div className="grid grid-cols-1 gap-4 sm:gap-6 sm:grid-cols-2 lg:grid-cols-3">
                                        {departments?.length ? <AddNewDepartment /> : null}
                                        {isAddingNewDepartment ? <NewDepartments id={0} name={""} subDepartments={[]} editable={false} editParent={false} /> : null}
                                        {Array.from(departments).map((department: IDepartment) => (
                                            <>
                                                {department?.editParent ? <NewDepartments {...department} /> : <ViewDepartment department={department} key={department?.id} />}

                                            </>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                : isError ? <Warning className="pt-24" title="Something went wrong!" message="Please refresh to try again" btnText="Refresh" />
                    : null

            }
        </>
    );
}
