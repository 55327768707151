import { apiSlice } from "../../../shared/slices/apiSlice";
import { tagTypes } from "../../../shared/utils/constantData";
import { ICloseJob } from "../../../shared/utils/interfaces/apisInterfaces";

const { jobs } = tagTypes;
export const jobListApiSlice = apiSlice.injectEndpoints({

    endpoints: (builder: any) => ({

        getJobsList: builder.query({
            query: (args: any) => {
                const { page, jobsPerPage, order, hideDrafts, shortVersion, includeApplications ,departmentIds} = args;
                return {
                    url: '/jobs?',
                    params: { page, jobsPerPage, order, hideDrafts, shortVersion, includeApplications, departmentIds: departmentIds ? departmentIds : undefined },
                };
            },
            providesTags: [jobs],
        }),
        closeJob: builder.mutation({
            query: (initialActionsData: ICloseJob) => ({
                url: 'jobs/status',
                method: 'PATCH',
                body: initialActionsData
            }),
            invalidatesTags: (_result: any, error: any) => error ? [] : [jobs]
        }),
        deleteJob: builder.mutation({
            query: (initialActionsData: ICloseJob) => ({
                url: 'jobs/status',
                method: 'PATCH',
                body: initialActionsData
            }),
            invalidatesTags: (_result: any, error: any) => error ? [] : [jobs]
        }),
    }),
})

export const {
    useGetJobsListQuery,
    useCloseJobMutation,
    useDeleteJobMutation
} = jobListApiSlice
