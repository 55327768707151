import { Empty } from 'antd';
import EmptyImage from '../../assets/images/noPublishJob.svg';
import './emptyList.scss'
export const EmptyList = ({ title, description, className, image }: { title: string, description?: string, className?: string, image?: React.ReactNode }) => {
    return (
        <>
            <Empty
                image={image ? image : EmptyImage}
                className={`pt-7 mb-5 ${className ?? ''}`}
                imageStyle={{ display: 'flex', justifyContent: 'center' }}
                description={
                    <>
                        <span className={`text-xl font-semibold leading-8 text-gray-600`}>
                            {title}
                        </span>
                        {description && <p className={`text-lg font-normal leading-6 text-gray-600`}>{description}</p>}
                    </>
                }
            >

            </Empty>
        </>
    );
}