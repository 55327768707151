import { useDispatch, useSelector } from 'react-redux'
import Plus from '../../../../shared/assets/images/darkPlus.svg'
import { selectDepartments, setDepartmentsData, setIsAddingNewDepartment } from '../../slice/departmentsSlice'
import { ISubDepartment } from '../../../../shared/utils/interfaces/departmentInterface'
export const AddNewDepartment = () => {
    const dispatch = useDispatch()
    const allDepartments = useSelector(selectDepartments)
    const handleAddNewDepartment = () => {
        dispatch(setDepartmentsData(allDepartments.map((dept) => {
            return {
                ...dept, editParent: false,editable:false,updateSubButton:false,
                subDepartments: dept.subDepartments?.map((sub:ISubDepartment) => ({
                    ...sub,
                    editSub: false
                })),
            }
        })));
        dispatch(setIsAddingNewDepartment(true))
    }
    return (
        <>
            <div className="w-[16.625rem] h-[12rem] p-5 rounded-2xl border border-dashed border-gray-400 flex-col justify-center items-center gap-1.5 inline-flex cursor-pointer" onClick={handleAddNewDepartment}>
                <img src={Plus} alt="Plus" />
                <div className="text-base font-bold leading-normal text-center text-gray-500" >Add New<br />Department</div>
            </div>
        </>
    )
}
